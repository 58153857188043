import { useEffect, useMemo, useState } from 'react';

import type { CellComponent } from '@/features/RidesTable/types';
import type { Dayjs } from '@/lib/dayjs';

import { ClockIcon } from '@radix-ui/react-icons';

import Cell from '@/features/RidesTable/components/Table/Cell';
import RideStatusBadge from '@/features/RideStatusBadge';
import {
  dispatcherRideBadgeText,
  formatDisplay,
  humanizedTimeliness,
  remainingTime,
  withinWindow,
} from '@/features/RideStatusBadge/helpers';
import {
  ICONS,
  RideStatusBadgeText,
  STYLES,
} from '@/features/RideStatusBadge/types';
import { useInterval } from '@/hooks/useInterval';
import dayjs from '@/lib/dayjs';

const DELAY_IN_MS = 1000;
const ZERO_TIME_REMAINING = '0:00 left';

const RideStatusBadgeCell: CellComponent = ({ ride }) => {
  const [timeRemaining, setTimeRemaining] = useState<string | null>(null);
  const isRunning =
    timeRemaining !== null && timeRemaining !== ZERO_TIME_REMAINING;

  const { name, biddingWindowCutoff, ppCutoffTime, badgeText } = useMemo(
    () => ({
      name: ride.riderFullName.split(' ').join('-').toLowerCase(),
      biddingWindowCutoff: dayjs
        .utc(ride.communityArrival)
        .add(ride.biddingWindow, 'second'),
      ppCutoffTime: dayjs
        .utc(ride.preferredProviderArrival)
        .add(ride.ppBiddingWindow, 'second'),
      badgeText: dispatcherRideBadgeText(ride),
    }),
    [ride],
  );

  const setFormattedDisplay = (cutoff: Dayjs) =>
    setTimeRemaining(formatDisplay(remainingTime(cutoff)));

  const setAfterTickValues = () => {
    if (!withinWindow(biddingWindowCutoff)) {
      setTimeRemaining(ZERO_TIME_REMAINING);
    }

    if (withinWindow(ppCutoffTime)) {
      setFormattedDisplay(ppCutoffTime);
    } else if (withinWindow(biddingWindowCutoff)) {
      setFormattedDisplay(biddingWindowCutoff);
    }
  };

  useInterval(() => setAfterTickValues(), isRunning ? DELAY_IN_MS : null);

  useEffect(() => {
    if (withinWindow(ppCutoffTime)) {
      setFormattedDisplay(ppCutoffTime);
    } else if (withinWindow(biddingWindowCutoff)) {
      setFormattedDisplay(biddingWindowCutoff);
    }
  }, [ride]);

  const style = STYLES[badgeText];
  const Icon = ICONS[badgeText];
  let SubIcon = null;
  let subtext = null;

  /**
   * Ride status should immediately switch to `processing` after the bidding window closes.
   */
  if (timeRemaining && timeRemaining !== ZERO_TIME_REMAINING) {
    subtext = timeRemaining;
    SubIcon = ClockIcon;
  } else if (badgeText === RideStatusBadgeText.IN_PROGRESS) {
    subtext = humanizedTimeliness(ride);
  }

  const displayTooltip = withinWindow(ppCutoffTime);
  const displaySpinner =
    badgeText === RideStatusBadgeText.PROCESSING ||
    timeRemaining === ZERO_TIME_REMAINING;

  return (
    <Cell
      label="Status"
      tdClass="time-remaining -overflow"
      href={`/dispatcher/ride/${ride.id}`}
      anchorClass="py-8"
    >
      <RideStatusBadge.Root color={style}>
        {displaySpinner && (
          <div className="loader-spin" data-testid="loader-spin" />
        )}

        <RideStatusBadge.Content id={`${name}-bid-status-message`}>
          {Icon && <Icon />}
          {badgeText}
        </RideStatusBadge.Content>

        {displayTooltip && (
          <RideStatusBadge.Tooltip>
            You can claim this trip prior to others in the area for a set period
            of time.
          </RideStatusBadge.Tooltip>
        )}

        {subtext && (
          <RideStatusBadge.Subtext>
            {SubIcon && <SubIcon />} {subtext}
          </RideStatusBadge.Subtext>
        )}
      </RideStatusBadge.Root>
    </Cell>
  );
};

export default RideStatusBadgeCell;
