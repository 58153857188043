import { useEffect, useMemo } from 'react';

import type { RideColumn } from '@/features/RidesTable/types';
import type { AppDispatch } from '@/store';
import type {
  AssignedStatus,
  DispatcherRideStatusBadge,
  Entries,
  Ride,
  VehicleType,
} from '@/types';

import { Cross2Icon } from '@radix-ui/react-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';

import BookTimeCell from '@/components/RidesTable/BookTimeCell';
import BulkActionCell from '@/components/RidesTable/BulkActionCell';
import BulkActionHeaderBar from '@/components/RidesTable/BulkActionCell/BulkActionHeaderBar';
import RideStatusBadgeCell from '@/components/RidesTable/dispatcher/RideStatusBadgeCell';
import DropoffLocationCell from '@/components/RidesTable/DropoffLocationCell';
import RideStatusBadgeCellOld from '@/components/RidesTable/old/RideStatusBadgeCell';
import PatientCell from '@/components/RidesTable/PatientCell';
import PickupLocationCell from '@/components/RidesTable/PickupLocationCell';
import PickupTimeCell from '@/components/RidesTable/PickupTimeCell';
import QuickActionCell from '@/components/RidesTable/QuickActionCell';
import VehicleTypeCell from '@/components/RidesTable/VehicleTypeCell';
import { useAuth } from '@/contexts/AuthProvider';
import Filters from '@/features/RidesTable/components/Filters';
import Header from '@/features/RidesTable/components/Header';
import ActionHeader from '@/features/RidesTable/components/Table/ActionHeader';
import TableHandler from '@/features/RidesTable/components/TableHandler';
import useUserPreferredColumns from '@/features/RidesTable/hooks/useUserPreferredColumns';
import {
  resetFilters,
  selectRideFilters,
  setAssignedStatusBadges,
  setAssignedStatuses,
  setEndDate,
  setItemsPerPage,
  setPage,
  setSearch,
  setShowWillCall,
  setSortField,
  setSortType,
  setStartDate,
  setVehicleTypes,
} from '@/features/RidesTable/store/ridesFilterSlice';
import {
  selectSelectedRides,
  setAllRidesSelected,
  setSelectedRides,
} from '@/features/RidesTable/store/selectedRidesSlice';
import {
  ASSIGNED_STATUSES,
  DEFAULT_SCHEDULED_STATUSES,
  DEFAULT_SCHEDULED_STATUSES_OLD,
  DISPATCHER_VEHICLE_TYPES,
} from '@/types';

const BASE_COLUMNS: RideColumn[] = [
  {
    Cell: PatientCell,
    removeable: false,
    label: 'Rider Name',
    sortKey: 'rider_name',
    href: ({ id }) => `/dispatcher/ride/${id}`,
  },
  {
    Cell: PickupLocationCell,
    label: 'Pickup',
    removeable: false,
    sortKey: 'pickup_location',
    href: ({ id }) => `/dispatcher/ride/${id}`,
  },
  {
    Cell: VehicleTypeCell,
    label: 'Vehicle Type',
    removeable: false,
    sortKey: 'vehicle_type',
    hideKey: 'vehicle',
    href: ({ id }) => `/dispatcher/ride/${id}`,
  },
  {
    Cell: DropoffLocationCell,
    label: 'Drop-off',
    removeable: false,
    sortKey: 'dropoff_location',
    href: ({ id }) => `/dispatcher/ride/${id}`,
  },
  {
    Cell: PickupTimeCell,
    label: 'Pickup Time',
    removeable: false,
    sortKey: 'start_time',
    href: ({ id }) => `/dispatcher/ride/${id}`,
  },
  {
    Cell: BookTimeCell,
    label: 'Book Time',
    removeable: false,
    sortKey: 'created_at',
    hideKey: 'book_time',
    href: ({ id }) => `/dispatcher/ride/${id}`,
  },
];

const ASSIGNED_STATUS_FILTERS = {
  [ASSIGNED_STATUSES.assigned]: 'Assigned',
  [ASSIGNED_STATUSES.inProgress]: 'In Progress',
  [ASSIGNED_STATUSES.submitEta]: 'Submit ETA',
  [ASSIGNED_STATUSES.willCallAssigned]: 'Will Call',
};

const ASSIGNED_STATUS_FILTERS_OLD = {
  [ASSIGNED_STATUSES.assigned]: 'Assigned',
  [ASSIGNED_STATUSES.inProgress]: 'In Progress',
  [ASSIGNED_STATUSES.submitEta]: 'Submit ETA',
  [ASSIGNED_STATUSES.willCall]: 'Will Call',
};

const vehicleList = Object.entries(DISPATCHER_VEHICLE_TYPES) as Entries<
  typeof DISPATCHER_VEHICLE_TYPES
>;

const Scheduled = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { currentUser } = useAuth();
  const selectedRides = useSelector(selectSelectedRides);

  const filters = useSelector(selectRideFilters);
  const clearSelectedRides = () => {
    dispatch(setSelectedRides([]));
    dispatch(setAllRidesSelected(false));
  };

  const setPaginationPage = (page: number) => {
    dispatch(setPage({ page }));
    clearSelectedRides();
  };

  const {
    reactDashboardQuickActionsSingleRide,
    reactDashboardBulkActions,
    reactDashboardBulkComplete,
    radiusSearchFilter,
    dispatcherApiStatusBadge,
  } = useFlags();

  const onClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    statuses: string[],
  ) => {
    e.preventDefault();
    dispatch(
      dispatcherApiStatusBadge
        ? setAssignedStatusBadges(statuses as DispatcherRideStatusBadge[])
        : setAssignedStatuses(statuses as AssignedStatus[]),
    );
  };

  const completedIsActive =
    filters.assignedStatus.length === 1 &&
    filters.assignedStatus[0] === ASSIGNED_STATUSES.completed;

  const canceledIsActive =
    filters.assignedStatus.length === 1 &&
    filters.assignedStatus[0] === ASSIGNED_STATUSES.canceled;

  const columns = [...BASE_COLUMNS];

  columns.push({
    Cell: dispatcherApiStatusBadge
      ? RideStatusBadgeCell
      : RideStatusBadgeCellOld,
    label: 'Status',
    removeable: false,
    sortKey: 'current_status',
    href: ({ id }: Ride) => `/dispatcher/ride/${id}`,
  });

  if (
    reactDashboardQuickActionsSingleRide &&
    !completedIsActive &&
    !canceledIsActive
  ) {
    columns.push({
      Cell: QuickActionCell,
      Header: ActionHeader,
      label: 'Quick Action',
      sortKey: 'quick_action',
    } as unknown as RideColumn);
  }

  if (reactDashboardBulkActions && !completedIsActive && !canceledIsActive) {
    columns.unshift({
      Cell: BulkActionCell,
      Header: ActionHeader,
      label: 'Bulk Action',
      sortKey: 'bulk_action',
    } as unknown as RideColumn);
  }

  const userPreferredColumns = useUserPreferredColumns(
    columns,
    currentUser.role,
    'assigned',
  );

  const tableFilters = useMemo(
    () =>
      dispatcherApiStatusBadge
        ? {
            ...filters,
            availableStatus: undefined,
            assignedStatus: undefined,
            assignedStatusBadges: undefined,
            availableStatusBadges: undefined,
            statusBadge: filters.assignedStatusBadges,
          }
        : {
            ...filters,
            availableStatus: undefined,
            assignedStatusBadges: [],
            availableStatusBadges: [],
          },
    [filters, dispatcherApiStatusBadge],
  );

  // clear selected rides on component dismount

  useEffect(() => {
    return () => {
      clearSelectedRides();
    };
  }, []);

  const { defaultStatuses, statusFilters } = useMemo(
    () => ({
      defaultStatuses: dispatcherApiStatusBadge
        ? DEFAULT_SCHEDULED_STATUSES
        : DEFAULT_SCHEDULED_STATUSES_OLD,
      statusFilters: dispatcherApiStatusBadge
        ? ASSIGNED_STATUS_FILTERS
        : ASSIGNED_STATUS_FILTERS_OLD,
    }),
    [dispatcherApiStatusBadge],
  );

  if (dispatcherApiStatusBadge === undefined) {
    return null;
  }

  return (
    <>
      <Header.Root>
        <div className="d-block d-sm-flex" style={{ gap: '0.25rem' }}>
          <Header.RangePicker
            endTime={filters.endTime}
            startTime={filters.startTime}
            setStartTime={(date: string) => dispatch(setStartDate(date))}
            setEndTime={(date: string) => dispatch(setEndDate(date))}
          />
          <Header.Search
            search={filters.search}
            setSearch={(input: string) => dispatch(setSearch(input))}
          />
        </div>

        <Header.StatusContainer>
          <Header.StatusButton
            active={!(completedIsActive || canceledIsActive)}
            onClick={(e) => onClick(e, defaultStatuses)}
            status={{
              assignedStatus: defaultStatuses,
            }}
            search={filters.search}
            startTime={filters.startTime}
            endTime={filters.endTime}
          >
            Assigned
          </Header.StatusButton>
          <Header.StatusButton
            active={completedIsActive}
            onClick={(e) => onClick(e, [ASSIGNED_STATUSES.completed])}
            status={{
              assignedStatus: [ASSIGNED_STATUSES.completed],
            }}
            search={filters.search}
            startTime={filters.startTime}
            endTime={filters.endTime}
          >
            Completed
          </Header.StatusButton>

          <Header.StatusButton
            active={canceledIsActive}
            onClick={(e) => onClick(e, [ASSIGNED_STATUSES.canceled])}
            status={{
              assignedStatus: [ASSIGNED_STATUSES.canceled],
            }}
            search={filters.search}
            startTime={filters.startTime}
            endTime={filters.endTime}
          >
            Canceled
          </Header.StatusButton>
        </Header.StatusContainer>

        <Header.ExportButton />
      </Header.Root>

      <TableHandler
        columns={userPreferredColumns}
        key="scheduled"
        setPage={setPaginationPage}
        filters={tableFilters}
        rowsPerPage={filters.items}
        onChangeRowsPerPage={(selected: number) =>
          dispatch(setItemsPerPage(selected))
        }
        sortType={filters.sortType}
        onChangeSortType={(type: string | null) => dispatch(setSortType(type))}
        sortField={filters.sortField}
        onChangeSortField={(field: string) => dispatch(setSortField(field))}
      >
        <BulkActionHeaderBar.Root visible={selectedRides.length > 0}>
          <div className="d-flex mb-3 mb-sm-0" style={{ gap: '0.25rem' }}>
            <BulkActionHeaderBar.BulkReleaseOption
              selectedRides={selectedRides}
            />
            {reactDashboardBulkComplete && (
              <BulkActionHeaderBar.BulkCompleteOption
                selectedRides={selectedRides}
              />
            )}
          </div>
        </BulkActionHeaderBar.Root>

        <Filters.Root visible={selectedRides.length === 0}>
          <div className="d-flex mb-3 mb-sm-0" style={{ gap: '0.25rem' }}>
            <Filters.VehicleType
              options={vehicleList}
              types={filters.vehicleTypes}
              setTypes={(types: VehicleType[]) =>
                dispatch(setVehicleTypes(types))
              }
            />
            <Filters.RequestingFacility />
            {!(completedIsActive || canceledIsActive) && (
              <Filters.Status
                key={filters.assignedStatusBadges.join('-')}
                defaultStatuses={defaultStatuses}
                currentlySelected={
                  dispatcherApiStatusBadge
                    ? filters.assignedStatusBadges
                    : filters.assignedStatus
                }
                items={statusFilters}
                onClose={(selected) =>
                  dispatcherApiStatusBadge
                    ? dispatch(
                        setAssignedStatusBadges(
                          selected as Partial<DispatcherRideStatusBadge>[],
                        ),
                      )
                    : dispatch(
                        setAssignedStatuses(
                          selected as Partial<AssignedStatus>[],
                        ),
                      )
                }
              />
            )}
            {radiusSearchFilter && <Filters.Distance />}
            <Filters.WillCall
              willCall={filters.willCall}
              setWillCall={(wc: boolean | null) =>
                dispatch(setShowWillCall(wc))
              }
            />
            <Filters.ClearFilters
              visible={!filters.isInit}
              reset={() => dispatch(resetFilters())}
              className="t-grey-50"
            >
              <Cross2Icon />
            </Filters.ClearFilters>
          </div>
          <Filters.EditColumns
            columns={[
              { text: 'Book Time', key: 'book_time' },
              { text: 'Vehicle Type', key: 'vehicle' },
            ]}
            page="assigned"
            userRole="dispatcher"
          />
        </Filters.Root>
      </TableHandler>
    </>
  );
};

export default Scheduled;
