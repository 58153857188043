import type { Ride, StreamableRide } from '@/types';

import dayjs, { Dayjs } from '@/lib/dayjs';

import { RideStatusBadgeText } from '@/features/RideStatusBadge/types';
import { RideCurrentStatus, TIMELINESS } from '@/types';

export const remainingTime = (cutoff: Dayjs) =>
  cutoff.diff(dayjs.utc(), 'second');

export const withinWindow = (cutoff: Dayjs) =>
  cutoff.isValid() && remainingTime(cutoff) > 0;

export const humanizedTimeliness = ({ timeliness }: Ride) => {
  switch (timeliness) {
    case TIMELINESS.on_time:
      return 'On time';
    case TIMELINESS.late_15_min:
      return 'Late 15 min';
    case TIMELINESS.late_30_min:
      return 'Late 30 min';
    case TIMELINESS.late_45_min:
      return 'Late 45 min';
    case TIMELINESS.late_60_min:
      return 'Late 60 min';
    case TIMELINESS.late_over_60_min:
      return 'Late over 60 min';
    default:
      return 'On time';
  }
};

export const doubleDigits = (num: number) =>
  num.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

export const formatDisplay = (seconds: number) =>
  `${Math.floor(seconds / 60)}:${doubleDigits(seconds % 60)} left`;

const COMMUNITY_TRANSPORTATION_COMPANY_ID = 0;

export const dispatcherRideBadgeText = (
  ride: StreamableRide,
): RideStatusBadgeText => {
  const biddingWindowCutoff = dayjs
    .utc(ride.timerStartTime)
    .add(ride.biddingWindow, 'second');

  const ppCutoffTime = dayjs
    .utc(ride.timerStartTime)
    .add(ride.ppBiddingWindow, 'second');

  if (
    ride.isDeclined &&
    ride.transportationCompanyId === COMMUNITY_TRANSPORTATION_COMPANY_ID
  ) {
    return RideStatusBadgeText.DECLINED;
  }

  if (ride.hasBid && withinWindow(biddingWindowCutoff)) {
    return RideStatusBadgeText.RESPONDED;
  }

  if (ride.currentStatus === RideCurrentStatus.COMPLETED) {
    return RideStatusBadgeText.COMPLETED;
  }

  if (ride.currentStatus === RideCurrentStatus.CANCELED) {
    return RideStatusBadgeText.CANCELED;
  }

  if (ride.processingRide) {
    return RideStatusBadgeText.PROCESSING;
  }

  if (ride.autoAssigned && !ride.hasBid) {
    return RideStatusBadgeText.SUBMIT_ETA;
  }

  if (ride.willCall) {
    return RideStatusBadgeText.NOT_STARTED;
  }

  if (ride.currentStatus === RideCurrentStatus.QUEUED && !ride.claimed) {
    if (ride.streamed) {
      return RideStatusBadgeText.NEW_RIDE;
    }

    if (
      ride.preferredProviderArrival &&
      withinWindow(ppCutoffTime) &&
      !ride.processingRide
    ) {
      return RideStatusBadgeText.PREFERRED;
    }

    return RideStatusBadgeText.AVAILABLE;
  }

  if (ride.timeliness !== null) {
    return RideStatusBadgeText.IN_PROGRESS;
  }

  return RideStatusBadgeText.ASSIGNED;
};
