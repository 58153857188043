import { useEffect, useMemo } from 'react';
import * as React from 'react';

import type { RideColumn } from '@/features/RidesTable/types';
import type { AppDispatch } from '@/store';
import type {
  AvailableStatus,
  DispatcherRideStatusBadge,
  Entries,
  Ride,
  VehicleType,
} from '@/types';

import { Cross2Icon } from '@radix-ui/react-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';

import BookTimeCell from '@/components/RidesTable/BookTimeCell';
import BulkActionCell from '@/components/RidesTable/BulkActionCell';
import BulkActionHeaderBar from '@/components/RidesTable/BulkActionCell/BulkActionHeaderBar';
import RideStatusBadgeCell from '@/components/RidesTable/dispatcher/RideStatusBadgeCell';
import DropoffLocationCell from '@/components/RidesTable/DropoffLocationCell';
import RideStatusBadgeCellOld from '@/components/RidesTable/old/RideStatusBadgeCell';
import PatientCell from '@/components/RidesTable/PatientCell';
import PickupLocationCell from '@/components/RidesTable/PickupLocationCell';
import PickupTimeCell from '@/components/RidesTable/PickupTimeCell';
import QuickActionCell from '@/components/RidesTable/QuickActionCell';
import VehicleTypeCell from '@/components/RidesTable/VehicleTypeCell';
import { useAuth } from '@/contexts/AuthProvider';
import Filters from '@/features/RidesTable/components/Filters';
import Header from '@/features/RidesTable/components/Header';
import ActionHeader from '@/features/RidesTable/components/Table/ActionHeader';
import TableHandler from '@/features/RidesTable/components/TableHandler';
import useUserPreferredColumns from '@/features/RidesTable/hooks/useUserPreferredColumns';
import {
  resetFilters,
  selectRideFilters,
  setAvailableStatusBadges,
  setAvailableStatuses,
  setEndDate,
  setItemsPerPage,
  setPage,
  setSearch,
  setShowWillCall,
  setSortField,
  setSortType,
  setStartDate,
  setVehicleTypes,
} from '@/features/RidesTable/store/ridesFilterSlice';
import {
  selectSelectedRides,
  setAllRidesSelected,
  setSelectedRides,
} from '@/features/RidesTable/store/selectedRidesSlice';
import {
  AVAILABLE_STATUSES,
  DEFAULT_COMMUNITY_STATUSES,
  DEFAULT_COMMUNITY_STATUSES_OLD,
  DISPATCHER_VEHICLE_TYPES,
} from '@/types';

const BASE_COLUMNS: RideColumn[] = [
  {
    Cell: PatientCell,
    removeable: false,
    label: 'Rider Name',
    sortKey: 'rider_name',
    href: ({ id }) => `/dispatcher/ride/${id}`,
  },
  {
    Cell: PickupLocationCell,
    label: 'Pickup',
    removeable: false,
    sortKey: 'pickup_location',
    href: ({ id }) => `/dispatcher/ride/${id}`,
  },
  {
    Cell: VehicleTypeCell,
    label: 'Vehicle Type',
    removeable: false,
    sortKey: 'vehicle_type',
    hideKey: 'vehicle',
    href: ({ id }) => `/dispatcher/ride/${id}`,
  },
  {
    Cell: DropoffLocationCell,
    label: 'Drop-off',
    removeable: false,
    sortKey: 'dropoff_location',
    href: ({ id }) => `/dispatcher/ride/${id}`,
  },
  {
    Cell: PickupTimeCell,
    label: 'Pickup Time',
    removeable: false,
    sortKey: 'start_time',
    href: ({ id }) => `/dispatcher/ride/${id}`,
  },
  {
    Cell: BookTimeCell,
    label: 'Book Time',
    removeable: false,
    sortKey: 'created_at',
    hideKey: 'book_time',
    href: ({ id }) => `/dispatcher/ride/${id}`,
  },
];

const COMMUNITY_STATUS_FILTERS = {
  [AVAILABLE_STATUSES.available]: 'Available',
  [AVAILABLE_STATUSES.preferred]: 'Preferred',
  [AVAILABLE_STATUSES.responded]: 'Responded',
  [AVAILABLE_STATUSES.willCallAvailable]: 'Will Call',
  [AVAILABLE_STATUSES.processing]: 'Processing',
};

const COMMUNITY_STATUS_FILTERS_OLD = {
  [AVAILABLE_STATUSES.available]: 'Available',
  [AVAILABLE_STATUSES.preferred]: 'Preferred',
  [AVAILABLE_STATUSES.responded]: 'Responded',
  [AVAILABLE_STATUSES.willCall]: 'Will Call',
};

const vehicleList = Object.entries(DISPATCHER_VEHICLE_TYPES) as Entries<
  typeof DISPATCHER_VEHICLE_TYPES
>;

const Community = () => {
  const { currentUser } = useAuth();
  const {
    reactDashboardQuickActionsSingleRide,
    reactDashboardBulkActions,
    radiusSearchFilter,
    dispatcherApiStatusBadge,
  } = useFlags();

  const dispatch = useDispatch<AppDispatch>();
  const selectedRides = useSelector(selectSelectedRides);
  const filters = useSelector(selectRideFilters);
  const clearSelectedRides = () => {
    dispatch(setSelectedRides([]));
    dispatch(setAllRidesSelected(false));
  };
  const setPaginationPage = (page: number) => {
    dispatch(setPage({ page }));
    clearSelectedRides();
  };

  const onClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    statuses: AvailableStatus[],
  ) => {
    e.preventDefault();
    dispatch(
      dispatcherApiStatusBadge
        ? setAvailableStatusBadges(
            statuses as unknown as DispatcherRideStatusBadge[],
          )
        : setAvailableStatuses(statuses),
    );
  };

  const declinedIsActive =
    filters.availableStatus.length === 1 &&
    filters.availableStatus[0] === AVAILABLE_STATUSES.declined;

  const columns = [...BASE_COLUMNS];

  columns.push({
    Cell: dispatcherApiStatusBadge
      ? RideStatusBadgeCell
      : RideStatusBadgeCellOld,
    label: 'Status',
    removeable: false,
    sortKey: 'current_status',
    href: ({ id }: Ride) => `/dispatcher/ride/${id}`,
  });

  if (reactDashboardQuickActionsSingleRide) {
    columns.push({
      Cell: QuickActionCell,
      Header: ActionHeader,
      label: 'Quick Action',
      sortKey: 'quick_action',
    } as unknown as RideColumn);
  }

  if (reactDashboardBulkActions && !declinedIsActive) {
    columns.unshift({
      Cell: BulkActionCell,
      Header: ActionHeader,
      label: 'Bulk Action',
      sortKey: 'bulk_action',
    } as unknown as RideColumn);
  }

  const userPreferredColumns = useUserPreferredColumns(
    columns,
    currentUser.role,
    'community',
  );

  const tableFilters = useMemo(
    () =>
      dispatcherApiStatusBadge
        ? {
            ...filters,
            availableStatus: undefined,
            assignedStatus: undefined,
            assignedStatusBadges: undefined,
            availableStatusBadges: undefined,
            statusBadge: filters.availableStatusBadges,
          }
        : {
            ...filters,
            assignedStatus: undefined,
            assignedStatusBadges: undefined,
            availableStatusBadges: undefined,
          },
    [filters, dispatcherApiStatusBadge],
  );
  // clear selected rides on component dismount

  useEffect(() => {
    return () => {
      clearSelectedRides();
    };
  }, []);

  const { defaultStatuses, statusFilters } = useMemo(
    () => ({
      defaultStatuses: dispatcherApiStatusBadge
        ? DEFAULT_COMMUNITY_STATUSES
        : DEFAULT_COMMUNITY_STATUSES_OLD,
      statusFilters: dispatcherApiStatusBadge
        ? COMMUNITY_STATUS_FILTERS
        : COMMUNITY_STATUS_FILTERS_OLD,
    }),
    [dispatcherApiStatusBadge],
  );

  const onClose = (selected: string[]) => {
    dispatch(
      dispatcherApiStatusBadge
        ? setAvailableStatusBadges(
            selected as Partial<DispatcherRideStatusBadge>[],
          )
        : setAvailableStatuses(selected as Partial<AvailableStatus>[]),
    );
  };

  if (dispatcherApiStatusBadge === undefined) {
    return null;
  }

  return (
    <>
      <Header.Root>
        <div className="d-block d-sm-flex" style={{ gap: '0.25rem' }}>
          <Header.RangePicker
            endTime={filters.endTime}
            startTime={filters.startTime}
            setStartTime={(date: string) => dispatch(setStartDate(date))}
            setEndTime={(date: string) => dispatch(setEndDate(date))}
          />
          <Header.Search
            search={filters.search}
            setSearch={(input: string) => dispatch(setSearch(input))}
          />
        </div>

        <Header.StatusContainer>
          <Header.StatusButton
            active={!declinedIsActive}
            onClick={(e) => onClick(e, defaultStatuses)}
            status={{
              availableStatus: defaultStatuses,
            }}
            search={filters.search}
            startTime={filters.startTime}
            endTime={filters.endTime}
          >
            Available
          </Header.StatusButton>
          <Header.StatusButton
            active={declinedIsActive}
            onClick={(e) => onClick(e, [AVAILABLE_STATUSES.declined])}
            status={{
              availableStatus: [AVAILABLE_STATUSES.declined],
            }}
            search={filters.search}
            startTime={filters.startTime}
            endTime={filters.endTime}
          >
            Declined
          </Header.StatusButton>
        </Header.StatusContainer>
      </Header.Root>

      <TableHandler
        columns={userPreferredColumns}
        key="community"
        setPage={setPaginationPage}
        filters={tableFilters}
        rowsPerPage={filters.items}
        onChangeRowsPerPage={(selected: number) =>
          dispatch(setItemsPerPage(selected))
        }
        sortType={filters.sortType}
        onChangeSortType={(type: string | null) => dispatch(setSortType(type))}
        sortField={filters.sortField}
        onChangeSortField={(field: string) => dispatch(setSortField(field))}
      >
        <BulkActionHeaderBar.Root visible={selectedRides.length > 0}>
          <div className="d-flex mb-3 mb-sm-0" style={{ gap: '0.25rem' }}>
            <BulkActionHeaderBar.BulkDeclineOption
              selectedRides={selectedRides}
            />
          </div>
        </BulkActionHeaderBar.Root>

        <Filters.Root visible={selectedRides.length === 0}>
          <div className="d-flex mb-3 mb-sm-0" style={{ gap: '0.25rem' }}>
            <Filters.VehicleType
              options={vehicleList}
              types={filters.vehicleTypes}
              setTypes={(types: VehicleType[]) =>
                dispatch(setVehicleTypes(types))
              }
            />
            <Filters.RequestingFacility />
            {!declinedIsActive && (
              <Filters.Status
                key={filters.availableStatusBadges.join('-')}
                defaultStatuses={defaultStatuses}
                currentlySelected={
                  dispatcherApiStatusBadge
                    ? filters.availableStatusBadges
                    : filters.availableStatus
                }
                items={statusFilters}
                onClose={onClose}
              />
            )}
            {radiusSearchFilter && <Filters.Distance />}
            <Filters.WillCall
              willCall={filters.willCall}
              setWillCall={(wc: boolean | null) =>
                dispatch(setShowWillCall(wc))
              }
            />
            <Filters.ClearFilters
              visible={!filters.isInit}
              reset={() => dispatch(resetFilters())}
              className="t-grey-50"
            >
              <Cross2Icon />
            </Filters.ClearFilters>
          </div>
          <Filters.EditColumns
            columns={[
              { text: 'Book Time', key: 'book_time' },
              { text: 'Vehicle Type', key: 'vehicle' },
            ]}
            page="community"
            userRole="dispatcher"
          />
        </Filters.Root>
      </TableHandler>
    </>
  );
};

export default Community;
