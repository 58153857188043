import { useCallback, useEffect, useMemo, useState } from 'react';

import type { Ride, Role, StreamableRide } from '@/types';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { RideFilterState } from '../types';

import { QueryStatus } from '@reduxjs/toolkit/query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch } from 'react-redux';

import { useLazyGetRidesQuery } from '@/api';
import { useLazyGetRideQuery } from '@/api/rides/getRide';
import { useAuth } from '@/contexts/AuthProvider';
import { dispatcherRideBadgeText } from '@/features/RideStatusBadge/helpers';
import { RideStatusBadgeText } from '@/features/RideStatusBadge/types';
import { useActionCable, useChannel } from '@/hooks/useActionCable';
import * as logger from '@/lib/@datadog/browser-logs';
import { isCommunity, isScheduled, SCHEDULED_PATH } from '@/path_defs';
import {
  DEFAULT_CC_FILTER_STATUSES,
  DEFAULT_COMMUNITY_STATUSES,
  DEFAULT_SCHEDULED_STATUSES,
  ROLES,
} from '@/types';
import { camelizeKeys } from '@/utils/camelizeKeys';

import { setPage } from '../store/ridesFilterSlice';
import { setSelectedRides } from '../store/selectedRidesSlice';
import useWebsocket from './useWebsocket';

const defaultApiRes = {
  rides: [],
  facets: { hospitals: [], rideBookers: [] },
  totalCount: 0,
  pages: 0,
};

const getStatusBadges = (role: Role) => {
  const { pathname } = window.location;

  if (role === ROLES.dispatcher) {
    if (pathname === SCHEDULED_PATH) {
      return DEFAULT_SCHEDULED_STATUSES;
    }

    return DEFAULT_COMMUNITY_STATUSES;
  }

  if (role === ROLES.superUser || role === ROLES.careCoordinator) {
    return DEFAULT_CC_FILTER_STATUSES;
  }

  if (role === ROLES.admin) {
    return []; // TODO: Add admin status badges
  }

  logger.error(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `Invalid role or pathname for status badges: ${role} ${pathname}`,
  );
  return [];
};

const useRides = (filters: RideFilterState) => {
  const dispatch = useDispatch();

  const { dispatcherStatusBadgeWebsockets } = useFlags();
  const { actionCable } = useActionCable();
  const { currentUser } = useAuth();

  const dashboardWebsocket = useWebsocket(
    'DecoratedDashboardChannel',
    'dispatcher',
    currentUser.id,
  );

  const ridesWebsocket = useWebsocket(
    'DecoratedRidesChannel',
    'dispatcher',
    currentUser.id,
  );

  /**
   * Using a lazy query because refetch is set to always.
   *
   * WebSocket rides would cause a refetch.
   */
  const [
    getRidesQuery,
    {
      data: apiRides = defaultApiRes,
      isFetching,
      isError,
      error: rideFetchError,
    },
  ] = useLazyGetRidesQuery();

  const [getRideQuery] = useLazyGetRideQuery();

  const { subscribe: subRidesChannel, unsubscribe: unsubRidesChannel } =
    useChannel(actionCable, {
      verbose: process.env.NODE_ENV === 'development',
    });

  const { subscribe: subDashChannel, unsubscribe: unsubDashChannel } =
    useChannel(actionCable, {
      verbose: true, // process.env.NODE_ENV === 'development',
    });

  /**
   * Local state so websockets can update the list.
   */
  const [rides, setRides] = useState<StreamableRide[]>([]);

  /**
   * Used to determine the number of pages for pagination.
   *
   * We cannot use `apiRides.totalCount` directly because that is the count of the last API request,
   * before rides have potentially been removed client-side,  nor can we use `apiRides.pages`
   * because it may no longer be correct.
   */
  const [totalCount, setTotalCount] = useState<number>(
    apiRides.totalCount || 0,
  );

  const pageLimit = filters.items;
  const currPage = filters.page;
  const finalPage = Math.ceil(totalCount / pageLimit);

  const getRides = useCallback(() => {
    setRides([]);
    return getRidesQuery(filters)
      .then((res) => {
        if (res.isSuccess) {
          setRides(res.data.rides || []);
          setTotalCount(res.data.totalCount);
        }

        return res;
      })
      .catch((e: unknown) => logger.error(e));
  }, [filters, getRidesQuery]);

  const removeRideById = useCallback(
    (id: number) =>
      setRides((currRides) => {
        const filtered = currRides.filter((r) => {
          if (r.id === id) {
            setTotalCount((currCount) => currCount - 1);
            return false;
          }

          return true;
        });

        const listIsEmpty = filtered.length === 0;
        const serverSideRidesAvailable = totalCount > 1;
        const isOnLastPage = currPage === finalPage;

        if (listIsEmpty && serverSideRidesAvailable) {
          if (isOnLastPage) {
            dispatch(setPage({ page: currPage - 1 }));
          } else {
            getRides(); // eslint-disable-line @typescript-eslint/no-floating-promises
          }
        }

        return filtered;
      }),
    [currPage, dispatch, finalPage, getRides, totalCount],
  );

  const selectAllRides = () => {
    const rideIds = rides.map((ride) => ride.id);
    dispatch(setSelectedRides(rideIds));
  };

  const updateRideById = (id: number, params: Partial<StreamableRide>) => {
    setRides((curr) => {
      const idx = curr.findIndex((r) => r.id === id);

      // Do not _add_ new rides.
      if (idx === -1) {
        return curr;
      }

      const newRides = [...curr];

      newRides[idx] = {
        ...newRides[idx],
        ...params,
        updated: true,
      };

      return newRides;
    });
  };

  const getRide = useCallback(
    (ride: Ride) => {
      getRideQuery({
        rideId: ride.id,
        params: {
          statusBadge: getStatusBadges(currentUser.role),
        },
      })
        .unwrap()
        .then((incoming) => updateRideById(incoming.id, incoming))
        .catch((err: FetchBaseQueryError) => {
          if (err.status === 404 || err.status === 401) {
            removeRideById(ride.id);
            return;
          }

          if (typeof err.status === 'string') {
            logger.error(`Error fetching ride: ${err.error}`);
          } else {
            logger.error(`Error fetching ride: ${err.status}`);
          }
        });
    },
    [currentUser.role, getRideQuery, removeRideById],
  );

  const [messageHistory, setMessageHistory] = useState<number[]>([]);

  useEffect(() => {
    // Do not process messages if the user is a dispatcher.
    if (
      currentUser.role === ROLES.dispatcher &&
      !dispatcherStatusBadgeWebsockets
    ) {
      return;
    }

    if (
      !dashboardWebsocket.lastJsonMessage?.message?.id ||
      !dashboardWebsocket.lastMessage?.timeStamp
    ) {
      return;
    }

    if (messageHistory.includes(dashboardWebsocket.lastMessage.timeStamp)) {
      return;
    }

    setMessageHistory([
      ...messageHistory,
      dashboardWebsocket.lastMessage.timeStamp,
    ]);

    /**
     * We currently send ride related WS events to users that shouldn't receive them.
     * This discards the event if the ride does not exist in the current rides list.
     */
    const idx = rides.findIndex(
      (r) => r.id === dashboardWebsocket.lastJsonMessage.message.id,
    );
    if (idx === -1) {
      return;
    }

    getRide(dashboardWebsocket.lastJsonMessage.message);
  }, [
    currentUser.role,
    dashboardWebsocket.lastJsonMessage,
    getRide,
    dashboardWebsocket.lastMessage?.timeStamp,
    messageHistory,
    rides,
    dispatcherStatusBadgeWebsockets,
  ]);

  // Used to add new rides to the _community_ table when the v3 flag is off
  useEffect(() => {
    // Do not process messages if the user is not a dispatcher.
    if (currentUser.role !== ROLES.dispatcher) {
      return;
    }

    if (!dispatcherStatusBadgeWebsockets) {
      return;
    }

    if (
      !ridesWebsocket.lastJsonMessage?.message?.id ||
      !ridesWebsocket.lastMessage?.timeStamp
    ) {
      return;
    }

    if (messageHistory.includes(ridesWebsocket.lastMessage.timeStamp)) {
      return;
    }

    setMessageHistory([
      ...messageHistory,
      ridesWebsocket.lastMessage.timeStamp,
    ]);

    /**
     * We currently send ride related WS events to users that shouldn't receive them.
     * This discards the event if the ride does not exist in the current rides list.
     */
    const idx = rides.findIndex(
      (r) => r.id === ridesWebsocket.lastJsonMessage.message.id,
    );
    if (idx !== -1) {
      // Skip this message if the ride already exists in the current rides list.
      return;
    }

    getRideQuery({
      rideId: ridesWebsocket.lastJsonMessage.message.id,
      params: {
        statusBadge: getStatusBadges(currentUser.role),
      },
    })
      .unwrap()
      .then((incoming) => {
        setRides((ridesList) => {
          /**
           * Prevent auto assigned rides from showing up in the community
           */
          if (incoming.autoAssigned && isScheduled()) {
            setTotalCount((currCount) => currCount + 1);
            return [{ ...incoming, streamed: true }, ...ridesList];
          }

          if (isCommunity()) {
            setTotalCount((currCount) => currCount + 1);

            return [{ ...incoming, streamed: true }, ...ridesList];
          }

          return ridesList;
        });
      })
      .catch((err: FetchBaseQueryError) => {
        if (err.status === 404) {
          removeRideById(ridesWebsocket.lastJsonMessage.message.id);
          return;
        }

        if (typeof err.status === 'string') {
          logger.error(`Error fetching ride: ${err.error}`);
        } else {
          logger.error(`Error fetching ride: ${err.status}`);
        }
      });
  }, [
    currentUser.role,
    ridesWebsocket.lastJsonMessage,
    ridesWebsocket.lastMessage?.timeStamp,
    messageHistory,
    rides,
    dispatcherStatusBadgeWebsockets,
    getRideQuery,
    removeRideById,
  ]);

  // TODO: Remove this when removing dispatcherStatusBadgeWebsockets flag
  // Used to add new rides to the _community_ table when the v3 flag is off
  useEffect(() => {
    if (
      currentUser.role !== ROLES.dispatcher ||
      dispatcherStatusBadgeWebsockets
    ) {
      return;
    }

    const ridesChannel: ActionCable.ChannelNameWithParams = {
      channel: 'DecoratedRidesChannel', // New Rides
      current_user_id: currentUser.id, // eslint-disable-line camelcase
    };

    subRidesChannel(ridesChannel, {
      received: (data: { ride: Ride }) => {
        const incoming = camelizeKeys(data) as Ride;
        // TODO: Error handler with toast notification
        setRides((ridesList) => {
          const idx = ridesList.findIndex((r) => r.id === incoming.id);
          if (idx === -1) {
            /**
             * Prevent auto assigned rides from showing up in the community
             */
            if (incoming.autoAssigned && isScheduled()) {
              setTotalCount((currCount) => currCount + 1);
              return [{ ...incoming, streamed: true }, ...ridesList];
            }
            /**
             * Currently do not support adding new rides to Assigned tab
             * Can't differentiate if a ride is claimed by this user, a different user or belongs in community
             */
            if (isScheduled()) {
              return ridesList;
              // return [{ ...incoming, streamed: true }, ...ridesList];
            }

            if (isCommunity()) {
              setTotalCount((currCount) => currCount + 1);

              return [{ ...incoming, streamed: true }, ...ridesList];
            }

            return ridesList;
          }

          /**
           * This would be the user on the scheduled/assigned page.
           * Ignoring incoming ride because we can't check if this ride actually belongs to the user.
           *
           * TODO: Only broadcast rides users are authorized to view
           */
          return ridesList;
        });
      },
    });

    return unsubRidesChannel;
  }, [dispatcherStatusBadgeWebsockets]);

  // TODO: Remove this when removing dispatcherStatusBadgeWebsockets flag
  // Used to update _existing rides_ within the current dataset.
  useEffect(() => {
    if (
      currentUser.role !== ROLES.dispatcher ||
      dispatcherStatusBadgeWebsockets
    ) {
      return;
    }

    const dashboardChannel: ActionCable.ChannelNameWithParams = {
      channel: 'DecoratedDashboardChannel',
      scope: 'dispatcher',
    };

    subDashChannel(dashboardChannel, {
      received: (data: Ride) => {
        const incoming = camelizeKeys(data) as Ride;

        setRides((curr) => {
          const idx = curr.findIndex((r) => r.id === incoming.id);

          // Do not _add_ new rides to the current page with this WS channel.
          if (idx === -1) {
            return curr;
          }
          const newRides = [...curr] as StreamableRide[];
          // const rideStatus = new RideStatusBadgeService(incoming);
          const rideStatus = dispatcherRideBadgeText(incoming);

          /**
           * We currently broadcast all ride changes to the frontend which results in
           * rides, awarded to other transit companies, receiving an 'Assigned' badge.
           * When users click on this ride, the backend will redirect them with a flash
           * stating they cannot view this ride.
           *
           * Current UX design is to remove the ride from the community page.
           */
          if (isCommunity() && rideStatus === RideStatusBadgeText.ASSIGNED) {
            newRides.splice(idx, 1);

            return newRides;
          }

          newRides[idx] = {
            ...newRides[idx],
            ...incoming,
            updated: true,
          };

          return newRides;
        });
      },
    });

    return unsubDashChannel;
  }, [dispatcherStatusBadgeWebsockets]);

  return {
    count: totalCount,
    facets: apiRides.facets,
    getRides,
    isError,
    isFetching,
    rides,
    messages: rideFetchError?.data?.messages as {
      description: string;
      statusCode: string;
    }[],
    removeRideById,
    selectAllRides,
    updateRideById,
  };
};

export default useRides;
